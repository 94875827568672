import classNames from "classnames"
import React      from "react"

import { formatDateTime, getFirstAndLastName, Utils } from "../Utils"
import { WidgetTable } from "./WidgetTable"

const COLUMNS = [
  "Created at", "Company", "Scheduled By", "Name", "Email", "Status", "Date", "Appointment Number", "Professional", "Phone Number", "Observations", "Reply"
]

const SCHEDULING_EVENTS_STATUS_DATA = {
    "Appointment": {
      'completed': [ "Completed"  ,"bg-blue-100 text-blue-800"   ],
      0:           [ "Confirmed"  ,"bg-green-100 text-green-800" ],
      1:           [ "Unconfirmed", "bg-gray-100 text-gray-800"  ],
      2:           [ "Canceled"   , "bg-red-100 text-red-800"    ],
    },
    "NutritionServiceRequest": {
      0: [ "Pending"    , "bg-gray-100 text-gray-800"     ],
      1: [ "Accepted"   , "bg-green-100 text-green-800"   ],
      2: [ "Rejected"   , "bg-red-100 text-red-800"       ],
      3: [ "Canceled"   , "bg-orange-50 text-orange-700"  ],
      4: [ "Unconfirmed", "bg-yellow-100 text-yellow-800" ],
      5: [ "Expired"    , "bg-gray-100 text-gray-800"     ]
    }
}

const NUTRITION_SERVICE_REQUEST_SCHEDULING_STATUS = {
  EXPIRED: 5,
}

const SCHEDULING_STATUS_CHANGED_BY_ENUM = ['PROFESSIONAL', 'PATIENT', 'SECRETARY']

const SCHEDULING_STATUS_CHANGED_REASON = [
  'REJECTED BY PROFESSIONAL              ',
  'CANCELED BY PATIENT                   ',
  'CANCELED BY OVERLAPPING REQUEST       ',
  'REJECTED BY SCHEDULING SYSTEM DISABLED',
  'CANCELED BY SUBSCRIPTION EXPIRATION   ',
  'EXPIRED BY LACK PROFESSIONAL RESPONSE ',
  'EXPIRED BY LACK ACCOUNT CONFIRMATION  ',
]

function getStatus(schedulingEvent) {
  if (isAppointment(schedulingEvent) && schedulingEvent.appointmentCompleted === true)
    return SCHEDULING_EVENTS_STATUS_DATA[schedulingEvent.type]['completed']

  return SCHEDULING_EVENTS_STATUS_DATA[schedulingEvent.type][schedulingEvent.statusId]
}

function isAppointment(schedulingEvent) {
  return schedulingEvent.type === 'Appointment'
}

function isNutritionServiceRequest(schedulingEvent) {
  return schedulingEvent.type === 'NutritionServiceRequest'
}

function getAppointmentStatusChangedBy(schedulingEvent) {
  return SCHEDULING_STATUS_CHANGED_BY_ENUM[schedulingEvent.appointmentSchedulingStatusChangedById]
}

function getRequestStatusChangeReason(schedulingEvent) {
  return SCHEDULING_STATUS_CHANGED_REASON[schedulingEvent.nutritionServiceRequestStatusChangeReasonId]
}

function getStatusName(schedulingEvent) {
  return schedulingEvent.type + ' ' + getStatus(schedulingEvent)[0]
}

function getStatusClass(schedulingEvent) {
  return getStatus(schedulingEvent)[1]
}

function shouldShowAppointmentSchedulingStatusChangedBy(schedulingEvent) {
  return isAppointment(schedulingEvent) &&
    schedulingEvent.appointmentCompleted === false &&
    schedulingEvent.appointmentSchedulingStatusChangedById !== null
}

function shouldShowRequestStatusChangeReason(schedulingEvent) {
  return isNutritionServiceRequest(schedulingEvent) &&
    schedulingEvent.statusId == NUTRITION_SERVICE_REQUEST_SCHEDULING_STATUS.EXPIRED &&
    schedulingEvent.nutritionServiceRequestStatusChangeReasonId !== null
}

function computeCompanyCell(user) {
  return (
    <td className="px-6 py-4 whitespace-nowrap flex items-center">
      <img className="w-6 rounded-full flex-shrink-0 object-cover" src={user.company?.logoUrl} />
      <span className="text-sm font-medium text-gray-900 ml-2">{user.company?.name}</span>
    </td>
  )
}

function computeFamilyCell(user) {
  return (
    <td className="px-6 py-4 whitespace-nowrap flex items-center">
      <span className="text-sm font-medium text-gray-900 ml-2">Family</span>
    </td>
  )
}

function computeProfessionalCell(user) {
  return (
    <td className="px-6 py-4 whitespace-nowrap flex items-center">
      <span className="text-sm font-medium text-gray-900 ml-2">Professional</span>
    </td>
  )
}

function getCompanyField(user) {
  const cellFunctionMap = {
    'COMPANY': computeCompanyCell,
    'FAMILY': computeFamilyCell,
    'PARTNER': computeCompanyCell,
    'PROFESSIONAL': computeProfessionalCell,
  }

  return cellFunctionMap[user.origin](user)
}

function SchedulingEventsWidget({ title, schedulingEvents, userData, onFilterByPatient}) {
  return (
    <WidgetTable title={title} columns={COLUMNS}>
      {schedulingEvents.map(schedulingEvent => (
        <WidgetTable.TableRow key={schedulingEvent.type + schedulingEvent.id}>

          <WidgetTable.TableCellText text={formatDateTime(schedulingEvent.createdAt)} />
          { getCompanyField(schedulingEvent.user) }
          <WidgetTable.TableCellText text={schedulingEvent.hasRequest ? "Patient" : "Professional"} />
          <WidgetTable.TableCellText text={getFirstAndLastName(schedulingEvent.user.name)} onClickAction={() => onFilterByPatient(schedulingEvent.user.account.email, schedulingEvent.user.company?.id)} styleOverride='underline cursor-pointer font-semibold' textColor='text-teal-600'/>
          <WidgetTable.TableCellEmail email={schedulingEvent.user.account.email} />

          <td className="px-6 py-4 whitespace-nowrap">
            <>
              <span className={classNames("px-2 inline-flex text-sm leading-5 font-semibold rounded-full", getStatusClass(schedulingEvent))}>
                { getStatusName(schedulingEvent) }
              </span>
              { shouldShowAppointmentSchedulingStatusChangedBy(schedulingEvent) &&
                <div class="text-xs rounded-full px-2">
                  Updated by: { getAppointmentStatusChangedBy(schedulingEvent) }
                </div>
              }
              {
                shouldShowRequestStatusChangeReason(schedulingEvent) &&
                <div class="text-xs rounded-full px-2">
                  Status change reason: { getRequestStatusChangeReason(schedulingEvent) }
                </div>
              }
            </>
          </td>

          <WidgetTable.TableCellText text={formatDateTime(schedulingEvent.date)} />
          <WidgetTable.TableCellText text={schedulingEvent.appointmentNumber}/>
          <WidgetTable.TableCellProfessional professional={schedulingEvent.professional} />
          <WidgetTable.TableCellPhoneNumber phoneNumber={schedulingEvent.user.phoneNumber} />
          {Utils.permissions.canSeeNutritionServiceRequestsObservations(userData.account.accountRoleId) &&
            <>
              <WidgetTable.TableCellText text={schedulingEvent.observations} />
              <WidgetTable.TableCellText text={schedulingEvent.reply} />
            </>
          }
        </WidgetTable.TableRow>
      ))}
    </WidgetTable>
  )
}

export { SchedulingEventsWidget }
